const setCsrfMetaTags = function() {
  fetch('/api/csrf_token').then(function(response) {
    response.json().then(function(data) {

      if (document.getElementsByName("csrf-token").length == 0) {  

        var csrf_param = document.createElement('meta')
        csrf_param.setAttribute('name', "csrf-param")
        csrf_param.setAttribute('content', "authenticity_token");

        var csrf_token = document.createElement('meta')
        csrf_token.setAttribute('name', 'csrf-token')
        csrf_token.setAttribute('content', data["X-CSRF-Token"]);

        document.getElementsByTagName('head')[0].append(csrf_param, csrf_token)
      } else {
        document.getElementsByName("csrf-token")[0].setAttribute('content', data["X-CSRF-Token"]);
      }
    });
  })
  }

export default setCsrfMetaTags;
